import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import LoginPage from "./components/Users/Login";
import { Navbar, Nav, NavDropdown, Dropdown } from "react-bootstrap";
import UsersList from "./components/Users/UsersList";
import Logo from "./images/logo.png";
import Cookies from "js-cookie";
import { getUserFromJWT } from "./utils/jwt.decode";
import { ToastContainer } from "react-toastify";
import SendBulk from "./components/Bulk/SendBulk";
import SmsBulk from "./components/Bulk/SmsBulk";
import NewUser from "./components/Users/NewUser";
import SMSReport from "./components/Bulk/Reports/SMS/SMSReports";
import ViberExport from "./components/Bulk/Reports/ViberReports";
import HomePage from "./components/HomePage";

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: null,
      isLoggedIn: false,
    };
    this.reRender = this.reRender.bind(this);
    this.handleLogOut = this.handleLogOut.bind(this);
  }

  componentDidMount() {
    const user = getUserFromJWT();
    if (user) {
      this.setState({
        currentUser: {
          userName: user.userName,
          userId: user.userId,
          isSuperAdmin: user.isSuperAdmin,
          role_id: user.role_id,
        },
        isLoggedIn: true,
      });
      Cookies.set("currentUser", user.userName);
      Cookies.set("isSuperAdmin", user.isSuperAdmin);
      Cookies.set("role_id", user.role_id);
      Cookies.set("sender_id", user.sender_id);
    }
  }

  handleLogOut() {
    Cookies.remove("jwt");
    Cookies.remove("currentUser");
    Cookies.remove("isSuperAdmin");
    Cookies.remove("role");
    Cookies.remove("sender_id");
    this.setState({ currentUser: null, isLoggedIn: false });
    window.location.replace("/");
  }

  reRender() {
    this.forceUpdate();
    const user = getUserFromJWT();
    if (user)
      this.setState({
        currentUser: {
          userName: user.userName,
          userId: user.userId,
          role_id: user.role_id,
        },
        isLoggedIn: true,
      });
    console.log(user);
  }

  render() {
    if (this.state.isLoggedIn) {
      return (
        <Router>
          <ToastContainer />
          <Navbar
            sticky="top"
            expand="lg"
            variant="light"
            className="main-navigation"
          >
            <Navbar.Brand>
              <Link to="/" className="navbar-brand">
                <img src={Logo} alt="vsc-logo" className="height-70" />
              </Link>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="mr-auto">
                {this.state.currentUser &&
                this.state.currentUser.role_id == 1 ? (
                  <Nav>
                    <Link to="/users" className="nav-link-item">
                      <i className="material-icons">people</i>Users
                    </Link>
                  </Nav>
                ) : null}
                {this.state.currentUser.role_id == 2 ||
                this.state.currentUser.role_id == 4 ? (
                  <Nav>
                    <Link to="/send-sms-bulk" className="nav-link-item">
                      <i className="material-icons">message</i>SMS Bulk
                    </Link>
                  </Nav>
                ) : null}
                {this.state.currentUser.role_id == 3 ||
                this.state.currentUser.role_id == 4 ? (
                  <Nav>
                    <Link to="/send-bulk" className="nav-link-item">
                      <i className="material-icons">phone_in_talk</i>Viber Bulk
                    </Link>
                  </Nav>
                ) : null}
                <Nav>
                  <Dropdown>
                    <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                      Reports
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {(this.state.currentUser &&
                        this.state.currentUser.role_id == 1) ||
                      this.state.currentUser.role_id == 2 ||
                      this.state.currentUser.role_id == 4 ? (
                        <Dropdown.Item href="/sms-reports">
                          <Link to="/sms-reports">
                            <i className="material-icons">sms</i>
                            SMS
                          </Link>
                        </Dropdown.Item>
                      ) : null}
                      {(this.state.currentUser &&
                        this.state.currentUser.role_id == 1) ||
                      this.state.currentUser.role_id == 3 ||
                      this.state.currentUser.role_id == 4 ? (
                        <Dropdown.Item href="/viber-reports">
                          <Link to="/viber-reports">
                            <i className="material-icons">phone_in_talk</i>
                            VIBER
                          </Link>
                        </Dropdown.Item>
                      ) : null}
                    </Dropdown.Menu>
                  </Dropdown>
                </Nav>
              </Nav>

              <Nav>
                <i className="material-icons md-24 logged-user">
                  account_circle
                </i>
                <NavDropdown
                  title={Cookies.get("currentUser")}
                  id="basic-nav-dropdown"
                  className="nav-link-item"
                >
                  <NavDropdown.Item
                    onClick={this.handleLogOut}
                    className="nav-ling-item"
                  >
                    Log out
                  </NavDropdown.Item>
                </NavDropdown>
              </Nav>
            </Navbar.Collapse>
          </Navbar>

          <Switch>
            <Route exact path="/">
              <HomePage />
            </Route>
            <Route path="/users">
              <UsersList />
            </Route>
            <Route path="/new-user">
              <NewUser />
            </Route>
            <Route path="/send-bulk">
              <SendBulk />
            </Route>
            <Route path="/send-sms-bulk">
              <SmsBulk />
            </Route>
            <Route path="/sms-reports">
              <SMSReport />
            </Route>
            <Route path="/viber-reports">
              <ViberExport />
            </Route>
          </Switch>
        </Router>
      );
    } else {
      return <LoginPage afterLogin={this.reRender} />;
    }
  }
}
export default App;
