import Axios from "axios";
import Cookies from "js-cookie";
class SendersApi {
  async getAll() {
    try {
      const token = Cookies.get("jwt");
      const configAPi = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const r = await Axios.get(
        `${process.env.REACT_APP_API_URL}/senders/all`,
        configAPi
      );
      return r.data;
    } catch (error) {
      throw error;
    }
  }
}

export default new SendersApi();
