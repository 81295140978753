import Axios from "axios";
import Cookies from "js-cookie";

class UserApi {
  async getAll(pageNumber, totalCount, searchText) {
    const token = Cookies.get("jwt");
    const configAPi = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const r = await Axios.post(
        `${process.env.REACT_APP_API_URL}/users/all`,
        { pageNumber, totalCount, searchText },
        configAPi
      );
      return r.data;
    } catch (err) {
      throw err;
    }
  }

  async getAllForExport() {
    const token = Cookies.get("jwt");
    const configAPi = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const r = await Axios.get(
        process.env.REACT_APP_STAGE === "production"
          ? `${process.env.REACT_APP_API_URL}/users/export-all`
          : `${process.env.REACT_APP_API_URL}/users/export-all`,
        configAPi
      );
      return r.data.users;
    } catch (err) {
      throw err;
    }
  }

  async deleteUser(userId) {
    const token = Cookies.get("jwt");
    const configAPi = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const r = await Axios.delete(
        process.env.REACT_APP_STAGE === "production"
          ? `${process.env.REACT_APP_API_URL}/users/delete/${userId}`
          : `${process.env.REACT_APP_API_URL}/users/delete/${userId}`,
        configAPi
      );
      return r;
    } catch (err) {
      throw err;
    }
  }

  createNewUser(data) {
    const token = Cookies.get("jwt");
    const configAPi = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const url = `${process.env.REACT_APP_API_URL}/users/create`;

      const res = Axios.post(url, data, configAPi);
      return res;
    } catch (err) {
      throw err;
    }
  }

  async getSenders() {
    const token = Cookies.get("jwt");
    const configAPi = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const url = `${process.env.REACT_APP_API_URL}/users/senders`;

      const res = await Axios.get(url, configAPi);
      return res;
    } catch (err) {
      throw err;
    }
  }
}

export default new UserApi();
