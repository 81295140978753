import Axios from "axios";
import Cookies from "js-cookie";

class Reports {
    async getViberStatistics() {
        const token = Cookies.get("jwt");
        const configAPi = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        };

        try {
            const r = await Axios.get(
                process.env.REACT_APP_STAGE === "production" ?
                `${process.env.REACT_APP_API_URL}/reports/bot-statistics/all` :
                `${process.env.REACT_APP_API_URL}/reports/bot-statistics/all`,
                configAPi
            );
            return r.data;
        } catch (err) {
            throw err;
        }
    }

    async getSmsCampaignNames(dateFrom, dateTo) {
        const token = Cookies.get("jwt");
        const configAPi = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        };

        try {
            const r = await Axios.post(
                process.env.REACT_APP_STAGE === "production" ?
                `${process.env.REACT_APP_API_URL}/reports/sms-statistics/get-names` :
                `${process.env.REACT_APP_API_URL}/reports/sms-statistics/get-names`, { dateFrom, dateTo },
                configAPi
            );
            return r.data;
        } catch (err) {
            throw err;
        }
    }

    async getSmsCampaign(searchText) {
        const token = Cookies.get("jwt");
        const configAPi = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        };

        try {
            const r = await Axios.post(
                process.env.REACT_APP_STAGE === "production" ?
                `${process.env.REACT_APP_API_URL}/reports/sms-statistics/get-sms-tag` :
                `${process.env.REACT_APP_API_URL}/reports/sms-statistics/get-sms-tag`, { searchText },
                configAPi
            );
            return r.data;
        } catch (err) {
            throw err;
        }
    }

    async getAllForExport() {
        const token = Cookies.get("jwt");
        const configAPi = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        };

        try {
            const r = await Axios.get(
                process.env.REACT_APP_STAGE === "production" ?
                `${process.env.REACT_APP_API_URL}/users/export-all` :
                `${process.env.REACT_APP_API_URL}/users/export-all`,
                configAPi
            );
            return r.data.users;
        } catch (err) {
            throw err;
        }
    }

    async getTagNames(dateFrom, dateTo) {
        const token = Cookies.get("jwt");
        const configAPi = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        };

        try {
            const r = await Axios.post(
                process.env.REACT_APP_STAGE === "production" ?
                `${process.env.REACT_APP_API_URL}/reports/viber-bulk/get-tags` :
                `${process.env.REACT_APP_API_URL}/reports/viber-bulk/get-tags`, { dateFrom, dateTo },
                configAPi
            );
            return r.data;
        } catch (err) {
            throw err;
        }
    }

    async getTag(id) {
        const token = Cookies.get("jwt");
        const configAPi = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        };

        try {
            const r = await Axios.get(
                process.env.REACT_APP_STAGE === "production" ?
                `${process.env.REACT_APP_API_URL}/reports/viber-bulk/get-tag/${id}` :
                `${process.env.REACT_APP_API_URL}/reports/viber-bulk/get-tag/${id}`,
                configAPi
            );
            return r.data;
        } catch (err) {
            throw err;
        }
    }

    async exportViberBulk(id) {
        const token = Cookies.get("jwt");
        const configAPi = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        };

        try {
            const r = await Axios.post(
                process.env.REACT_APP_STAGE === "production" ?
                `${process.env.REACT_APP_API_URL}/reports/viber-bulk/export` :
                `${process.env.REACT_APP_API_URL}/reports/viber-bulk/export`, { id },
                configAPi
            );
            return r.data;
        } catch (err) {
            throw err;
        }
    }

    async exportSmsBulk(campaignLabel) {
        const token = Cookies.get("jwt");
        const configAPi = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        };

        try {
            const r = await Axios.post(
                process.env.REACT_APP_STAGE === "production" ?
                `${process.env.REACT_APP_API_URL}/reports/sms-bulk/export` :
                `${process.env.REACT_APP_API_URL}/reports/sms-bulk/export`, { campaignLabel },
                configAPi
            );
            return r.data;
        } catch (err) {
            throw err;
        }
    }
}

export default new Reports();