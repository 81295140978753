export const getRoleName = (id) => {
  switch (id) {
    case 1:
      return "Super Admin";
    case 2:
      return "SMS Only";
    case 3:
      return "Viber Only";
    case 4:
      return "Viber And SMS";

    default:
      break;
  }
};
