import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import SMSReportsBulk from "../SMS/SMSReportsBulk";
import SMSReportsSingle from "../SMS/SMSReportSigle";

export default () => (
  <Tabs>
    <TabList>
      <Tab>Bulk</Tab>
      <Tab>Single</Tab>
    </TabList>

    <TabPanel>
      <SMSReportsBulk />
    </TabPanel>
    <TabPanel>
      <SMSReportsSingle />
    </TabPanel>
  </Tabs>
);
