import Axios from "axios";
import Cookies from "js-cookie";
class BulkApi {
  async sendSmsBulk(req) {
    try {
      const token = Cookies.get("jwt");
      const configAPi = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      console.log(process.env.REACT_APP_API_URL);
      const r = await Axios.post(
        `${process.env.REACT_APP_API_URL}/bulk/sms`,
        req,
        configAPi
      );
      return r;
    } catch (error) {
      throw error;
    }
  }

  async sendSingleSmsBulk(req) {
    try {
      const token = Cookies.get("jwt");
      const configAPi = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      console.log(process.env.REACT_APP_API_URL);
      const r = await Axios.post(
        `${process.env.REACT_APP_API_URL}/bulk/singleSms`,
        req,
        configAPi
      );
      return r;
    } catch (error) {
      throw error;
    }
  }

  async sendBulk(req) {
    try {
      const token = Cookies.get("jwt");
      const configAPi = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const r = await Axios.post(
        `${process.env.REACT_APP_API_URL}/bulk/viber`,
        req,
        configAPi
      );
      return r;
    } catch (error) {
      throw error;
    }
  }

  async getSMSBulkReportForExport(tag) {
    try {
      const token = Cookies.get("jwt");
      const configAPi = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const r = await Axios.post(
        `${process.env.REACT_APP_API_URL}/bulk/sms/reports-export`,
        { tag },
        configAPi
      );
      return r.data;
    } catch (error) {
      throw error;
    }
  }

  async getSMSBulkReport(tag_id) {
    try {
      const token = Cookies.get("jwt");
      const configAPi = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const r = await Axios.post(
        `${process.env.REACT_APP_CENTRAL_HUB_URL}/sms/get-bulk`,
        { tag_id },
        configAPi
      );
      return r.data;
    } catch (error) {
      throw error;
    }
  }

  async getViberBulkReportForExport(dateFrom, dateTo) {
    try {
      const token = Cookies.get("jwt");
      const configAPi = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const r = await Axios.post(
        `${process.env.REACT_APP_API_URL}/bulk/viber/reports-export`,
        { dateFrom, dateTo },
        configAPi
      );
      return r.data;
    } catch (error) {
      throw error;
    }
  }

  async getViberBulkReportAll(dateFrom, dateTo, page) {
    try {
      const token = Cookies.get("jwt");
      const configAPi = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const r = await Axios.post(
        `${process.env.REACT_APP_API_URL}/bulk/viber/reports`,
        { dateFrom, dateTo, page },
        configAPi
      );
      return r.data;
    } catch (error) {
      throw error;
    }
  }

  async getCampings(dateFrom, dateTo, sender_id) {
    try {
      const token = Cookies.get("jwt");
      const configAPi = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const r = await Axios.post(
        `${process.env.REACT_APP_CENTRAL_HUB_URL}/sms/get-campings`,
        { dateFrom, dateTo, sender_id },
        configAPi
      );
      return r.data;
    } catch (error) {
      throw error;
    }
  }

  async getSmsSingle(dateFrom, dateTo, page, number, sender) {
    try {
      const token = Cookies.get("jwt");
      const configAPi = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const r = await Axios.post(
        `${process.env.REACT_APP_CENTRAL_HUB_URL}/sms/get-single`,
        { dateFrom, dateTo, page, number, sender },
        configAPi
      );
      return r.data;
    } catch (error) {
      throw error;
    }
  }
}

export default new BulkApi();
