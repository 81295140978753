import React, { Component } from 'react'
import 'react-toastify/dist/ReactToastify.css'
import { Row, Col } from 'react-bootstrap'
import UsersApi from '../../api/users.api'
import messagesApi from '../../api/messages.api'
import { toast } from 'react-toastify'
import Select from 'react-select'
import AllCombinations from './AllCombinations'
import TextOnly from './TextOnly'
import PhotoOnly from './PhotoOnly'
import TextAndButton from './TextAndButton'
import uploadsApi from '../../api/uploads.api'

export default class SendBulk extends Component {
  constructor(props) {
    super(props)
    this.state = {
      messageText: '',
      remainingCharacters: 1000,
      remainingCharErr: null,
      buttonText: '',
      buttonTextErr: null,
      buttonUrl: '',
      buttonUrlErr: null,
      imageUrl: '',
      imageUrlErr: null,
      tagLabel: '',
      tagLabelErr: null,
      selectedFile: null,
      numbersFileName: '',
      fileError: null,
      bulkOption: 0,
      textOnly: false,
      photoOnly: false,
      textAndButton: false,
    }
    this.handleChange = this.handleChange.bind(this)
    this.messageLabelChange = this.messageLabelChange.bind(this)
    this.onChangeHandler = this.onChangeHandler.bind(this)
    this.bulkOptionChange = this.bulkOptionChange.bind(this)
    this.resetState = this.resetState.bind(this)
  }

  bulkOptionChange(e) {
    this.setState({ bulkOption: e.value })
  }

  messageLabelChange(event) {
    this.setState({ messageLabelType: event.value })
  }
  handleChange(event) {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name

    if (target.name == 'messageText') {
      const remaining = 1000 - (this.state.messageText.length + 1)
      this.setState({
        remainingCharacters: remaining > 0 ? remaining : 0,
      })
    }
    this.setState({
      [name]: value,
    })
  }
  async SendBulk() {
    if (this.validateForm()) {
      var response = await messagesApi.sendBulk({
        text: this.state.messageText,
      })
      this.setState({
        messageText: '',
        remainingCharacters: 1000,
        remainingCharErr: null,
      })
      toast.success('Message has been sent')
    }
  }

  async onChangeHandler(event) {
    try {
      const target = event.target.files[0].name.slice(
        ((event.target.files[0].name.lastIndexOf('.') - 1) >>> 0) + 2,
      )
      if (target.toLowerCase() == 'xlsx') {
        const data = new FormData()
        data.append('file', event.target.files[0])
        var response = await uploadsApi.uploadNumbers(data)
        this.setState({
          numbersFileName: response.data.filename,
          fileError: '',
        })
      } else {
        this.setState({
          fileError: 'File should have .xlsx extension',
        })
      }
    } catch (err) {
      console.log(err)
    }
  }

  async resetState() {
    this.setState({
      messageText: '',
      remainingCharacters: 1000,
      remainingCharErr: null,
      buttonText: '',
      buttonTextErr: null,
      buttonUrl: '',
      buttonUrlErr: null,
      imageUrl: '',
      imageUrlErr: null,
      tagLabel: '',
      tagLabelErr: null,
      selectedFile: null,
      numbersFileName: '',
      fileError: null,
      bulkOption: 0,
      textOnly: false,
      photoOnly: false,
      textAndButton: false,
    })
  }
  render() {
    const options = [
      { value: 106, label: 'Text only' },
      { value: 107, label: 'Image only' },
      { value: 108, label: 'Text and button' },
      { value: 109, label: 'Text, image and button' },
    ]
    return (
      <Row className="table-section">
        <Col>
          <Col xs={12} md={6} className="mt-4 mb-4 ml-3">
            <Select
              options={options}
              placeholder="Choose bulk option"
              onChange={this.bulkOptionChange}
            />
          </Col>
          {
            {
              106: (
                <TextOnly
                  handleChange={this.handleChange}
                  messageText={this.state.messageText}
                  tagLabel={this.state.tagLabel}
                  remainingCharacters={this.state.remainingCharacters}
                  resetState={this.resetState}
                />
              ),
              107: (
                <PhotoOnly
                  handleChange={this.handleChange}
                  messageText={this.state.messageText}
                  tagLabel={this.state.tagLabel}
                  imageUrl={this.state.imageUrl}
                  onChangeHandler={this.onChangeHandler}
                  numbersFileName={this.state.numbersFileName}
                  fileError={this.state.fileError}
                  resetState={this.resetState}
                />
              ),
              108: (
                <TextAndButton
                  handleChange={this.handleChange}
                  messageText={this.state.messageText}
                  tagLabel={this.state.tagLabel}
                  buttonUrl={this.state.buttonUrl}
                  buttonText={this.state.buttonText}
                  remainingCharacters={this.state.remainingCharacters}
                  resetState={this.resetState}
                />
              ),
              109: (
                <AllCombinations
                  handleChange={this.handleChange}
                  messageText={this.state.messageText}
                  tagLabel={this.state.tagLabel}
                  buttonText={this.state.buttonText}
                  buttonUrl={this.state.buttonUrl}
                  imageUrl={this.state.imageUrl}
                  onChangeHandler={this.onChangeHandler}
                  numbersFileName={this.state.numbersFileName}
                  fileError={this.state.fileError}
                  selectedFile={this.state.selectedFile}
                  remainingCharacters={this.state.remainingCharacters}
                  resetState={this.resetState}
                />
              ),
            }[this.state.bulkOption]
          }
        </Col>
      </Row>
    )
  }
}
