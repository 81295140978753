import React, { Component } from "react";
import { Row, Col, Table, Container, Button } from "react-bootstrap";
import "react-funnel-pipeline/dist/index.css";
import DatePicker from "react-date-picker";
import bulkApi from "../../../../api/bulk.api";
import SmsBulkExport from "../../Exports/SmsBulkExport";
import Cookies from "js-cookie";
import Select from "react-select";
import usersApi from "../../../../api/users.api";

export default class SMSReportsBulk extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dateFrom: new Date(),
      dateTo: new Date(),
      data: null,
      role: Cookies.get("role_id"),
      searchText: "",
      searchType: "camping",
      sender_id: 0,
      messages: [],
      bulk_text: "",
      tag: "",
      senders: [],
    };
    this.onDateFromChange = this.onDateFromChange.bind(this);
    this.onDateToChange = this.onDateToChange.bind(this);
    this.getReports = this.getReports.bind(this);
    this.handlePageClick = this.handlePageClick.bind(this);
    this.setSearchTextState = this.setSearchTextState.bind(this);
    this.tagChange = this.tagChange.bind(this);
    this.senderChange = this.senderChange.bind(this);
  }

  async setSearchTextState(e) {
    this.setState({ searchText: e.target.value });
    this.forceUpdate();
  }

  async getCampings() {
    var tagnamesResults = await bulkApi.getCampings(
      this.state.dateFrom,
      this.state.dateTo,
      this.state.sender_id
    );
    this.setState({ tagNames: tagnamesResults });
  }

  async onDateFromChange(e) {
    this.setState({ dateFrom: e });
    this.forceUpdate();
  }

  async onDateToChange(e) {
    this.setState({ dateTo: e });
  }

  async handlePageClick(e) {
    await this.getReports(e.selected + 1);
  }

  async getReports(page) {
    try {
      var result = await bulkApi.getSMSBulkReportAll(
        this.state.dateFrom,
        this.state.dateTo,
        this.state.searchText,
        this.state.searchType,
        page
      );
      this.setState({ data: result.reports });
    } catch (error) {}
  }

  async componentDidMount() {
    if (this.state.role != 1) {
      this.setState({
        sender_id: Cookies.get("sender_id"),
      });
    } else {
      const sendersList = await usersApi.getSenders();
      console.log(sendersList.data);
      this.setState({ senders: sendersList.data });
    }
  }

  async tagChange(e) {
    var bulk = await bulkApi.getSMSBulkReport(e.value);
    this.setState({
      messages: bulk.messages,
      bulk_text: bulk.text,
      tag: e.label,
    });
    this.forceUpdate();
  }

  async senderChange(e) {
    this.setState({
      sender_id: e.value,
    });
    this.forceUpdate();
  }

  render() {
    return (
      <Container>
        <Row className="mb-4 mt-4">
          <Col>
            <Select
              options={this.state.tagNames}
              isSearchable="true"
              onChange={this.tagChange}
              placeholder="Select tag"
            ></Select>
          </Col>
          {this.state.role == 1 ? (
            <Col>
              <Select
                options={this.state.senders}
                isSearchable="true"
                onChange={this.senderChange}
                placeholder="Select sender"
              ></Select>
            </Col>
          ) : null}
          <Col></Col>
          <Col>
            <span>Date from </span>
            <DatePicker
              value={this.state.dateFrom}
              onChange={this.onDateFromChange}
              format="dd-MM-yyyy"
              disableClock={true}
              timeFormat={false}
            />
          </Col>
          <Col>
            <span>Date to </span>
            <DatePicker
              value={this.state.dateTo}
              onChange={this.onDateToChange}
              format="dd-MM-yyyy"
              disableClock={true}
              timeFormat={false}
            />
          </Col>
          <Col>
            <Button
              className="btn mr-2 button-standard"
              onClick={() => this.getCampings()}
            >
              <i className="material-icons">search</i>
              Search
            </Button>
            <SmsBulkExport messages={this.state.messages} tag={this.state.ta} />
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>Bulk text : {this.state.bulk_text}</Col>
        </Row>
        <Row>
          <Col>
            <h4 className="mt-5">SMS reports</h4>
            <Table className="mb-5">
              <thead className="table-head">
                <tr>
                  {this.state.role == 1 ? <th>Sender</th> : null}
                  <th>Destination</th>
                  <th>Status</th>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody>
                {this.state.messages && this.state.messages.length > 0 ? (
                  this.state.messages.map((i) => {
                    return (
                      <tr key={i._id} style={{ backgroundColor: "white" }}>
                        {this.state.role == 1 ? <td>{i.sms_sender}</td> : null}
                        <td>{i.phone}</td>
                        <td>{i.status}</td>
                        <td>{i.sent}</td>
                      </tr>
                    );
                  })
                ) : (
                  <tr style={{ textAlign: "center", backgroundColor: "white" }}>
                    <td colSpan={4}>No data..</td>
                  </tr>
                )}
              </tbody>
            </Table>
            {/* <ReactPaginate
              previousLabel={"< Prev"}
              nextLabel={"Next >"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={
                this.state.data && this.state.data.count
                  ? Math.ceil(this.state.data.count / 10)
                  : 1
              }
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={this.handlePageClick}
              containerClassName={"list-group-pagination pagination"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              disabledClassName={"page-item disabled"}
              activeClassName={"page-item active"}
            /> */}
          </Col>
        </Row>
      </Container>
    );
  }
}
