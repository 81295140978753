import React, { Component } from 'react'
import { Form } from 'react-bootstrap'
import Select from 'react-select'
import usersApi from '../../api/users.api'
import { Redirect } from 'react-router-dom'

export default class NewUser extends Component {
  constructor(props) {
    super(props)
    this.state = {
      senders: null,
      password: '',
      user_name: '',
      email: '',
      sender: '',
      sms_limit: 0,
      redirect: false,
      role_id: 0,
      sms_sender: '',
      viber_sender: '',
    }
    this.handleChange = this.handleChange.bind(this)
    this.bulkOptionChange = this.bulkOptionChange.bind(this)
  }

  bulkOptionChange(e) {
    this.setState({ role_id: e.value })
  }

  handleChange(event) {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name

    this.setState({
      [name]: value,
    })
  }

  addNewUser() {
    try {
      const payload = {
        user_name: this.state.user_name,
        password: this.state.password,
        viber_sender: this.state.viber_sender,
        sms_sender: this.state.sms_sender,
        email: this.state.email,
        sms_limit: this.state.sms_limit,
        role_id: this.state.role_id,
      }
      usersApi.createNewUser(payload)
      this.setState({ redirect: true })
    } catch (error) {}
  }

  render() {
    const { redirect } = this.state
    const options = [
      { value: 2, label: 'Sms Only' },
      { value: 3, label: 'Viber Only' },
      { value: 4, label: 'Sms and Viber' },
    ]

    if (redirect) {
      return (
        <Redirect
          to={{
            pathname: '/users',
            state: { reload: true },
          }}
        />
      )
    }
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: '100px',
        }}
      >
        <Form style={{ width: '30%' }}>
          <Form.Group className="mb-3">
            <Form.Label>User name</Form.Label>
            <Form.Control
              placeholder="Enter user name"
              name="user_name"
              onChange={this.handleChange}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Password"
              onChange={this.handleChange}
              name="password"
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter email"
              name="email"
              onChange={this.handleChange}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Role</Form.Label>
            <Select
              options={options}
              placeholder="Select role"
              onChange={this.bulkOptionChange}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Viber Sender Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter viber sender"
              name="viber_sender"
              onChange={this.handleChange}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>SMS Sender Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter sms sender"
              name="sms_sender"
              onChange={this.handleChange}
              max={12}
              maxLength={12}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Sms limit</Form.Label>
            <Form.Control
              placeholder="Enter sms limit"
              name="sms_limit"
              onChange={this.handleChange}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <button
              className="btn btn-success"
              style={{ float: 'right' }}
              onClick={() => this.addNewUser()}
            >
              {' '}
              Add{' '}
            </button>
          </Form.Group>
        </Form>
      </div>
    )
  }
}
