import React from "react";
import ReactExport from "react-export-excel";
import BulkApi from "../../../api/bulk.api";
import { Button } from "react-bootstrap";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class SmsBulkExport extends React.Component {
  constructor(props) {
    super(props);

    this.sate = {
      messages: [],
    };
  }

  async componentDidMount() {
    try {      
      this.setState({ reports: this.props.messages });
    } catch (error) {
      throw error;
    }
  }
  async componentWillReceiveProps(props) {
    try {
      console.log(props);
      this.setState({ messages: props.messages });     
    } catch (error) {
      throw error;
    }
  }

  render() {
    return (
      <ExcelFile
        filename="SMS Report"
        element={<Button variant="btn mr-2 button-standard">Export</Button>}
      >
        {this.state && this.state.messages != null ? (
          <ExcelSheet data={this.state.messages} name="reports">
            <ExcelColumn label="Destination" value="phone" />
            <ExcelColumn label="Status" value="status" />
            <ExcelColumn label="Date" value="sent" />
          </ExcelSheet>
        ) : null}
      </ExcelFile>
    );
  }
}
export default SmsBulkExport;
