import React, { Component } from "react";
import {
  Row,
  Col,
  Table,
  Container,
  Button,
  FormControl,
} from "react-bootstrap";
import "react-funnel-pipeline/dist/index.css";
import DatePicker from "react-date-picker";
import bulkApi from "../../../../api/bulk.api";
import Cookies from "js-cookie";
import ReactPaginate from "react-paginate";

export default class SMSReportsSingle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dateFrom: new Date(),
      dateTo: new Date(),
      data: null,
      role: Cookies.get("role_id"),
      searchText: "",
      searchType: "camping",
      sender_id: 0,
      messages: [],
      bulk_text: "",
      tag: "",
    };
    this.onDateFromChange = this.onDateFromChange.bind(this);
    this.onDateToChange = this.onDateToChange.bind(this);
    this.getReports = this.getReports.bind(this);
    this.handlePageClick = this.handlePageClick.bind(this);
    this.setSearchTextState = this.setSearchTextState.bind(this);
    this.tagChange = this.tagChange.bind(this);
  }

  async setSearchTextState(e) {
    this.setState({ searchText: e.target.value });
    this.forceUpdate();
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  async getCampings() {
    var tagnamesResults = await bulkApi.getCampings(
      this.state.dateFrom,
      this.state.dateTo,
      this.state.sender_id
    );
    this.setState({ tagNames: tagnamesResults });
  }

  async onDateFromChange(e) {
    this.setState({ dateFrom: e });
    this.forceUpdate();
  }

  async onDateToChange(e) {
    this.setState({ dateTo: e });
  }

  async handlePageClick(e) {
    await this.getReports(e.selected + 1);
  }

  async getReports(page) {
    try {
      var result = await bulkApi.getSmsSingle(
        this.state.dateFrom,
        this.state.dateTo,
        page,
        this.state.searchText,
        this.state.sender_id
      );
      this.setState({ data: result });
    } catch (error) {}
  }

  async componentDidMount() {
    if (this.state.role != 1) {
      this.setState({ sender_id: Cookies.get("sender_id") });
    }
    await this.getReports();
  }

  async tagChange(e) {
    var bulk = await bulkApi.getSMSBulkReport(e.value);
    this.setState({
      messages: bulk.messages,
      bulk_text: bulk.text,
      tag: e.label,
    });
    this.forceUpdate();
  }

  render() {
    return (
      <Container>
        <Row className="mb-4 mt-4">
          <Col>
            <FormControl
              type="text"
              placeholder="Search number"
              className="mr-sm-2"
              onChange={this.setSearchTextState}
              name="searchText"
            />
          </Col>
          <Col></Col>
          <Col>
            <span>Date from </span>
            <DatePicker
              value={this.state.dateFrom}
              onChange={this.onDateFromChange}
              format="dd-MM-yyyy"
              disableClock={true}
              timeFormat={false}
            />
          </Col>
          <Col>
            <span>Date to </span>
            <DatePicker
              value={this.state.dateTo}
              onChange={this.onDateToChange}
              format="dd-MM-yyyy"
              disableClock={true}
              timeFormat={false}
            />
          </Col>
          <Col>
            <Button
              className="btn mr-2 button-standard"
              onClick={() => this.getReports()}
            >
              <i className="material-icons">search</i>
              Search
            </Button>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            <h4 className="mt-5">SMS reports</h4>
            <Table className="mb-5">
              <thead className="table-head">
                <tr>
                  {this.state.role == 1 ? <th>Sender</th> : null}
                  <th>Destination</th>
                  <th>Status</th>
                  <th>Text</th>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody>
                {this.state.data && this.state.data.reports && this.state.data.reports.length > 0 ? (
                  this.state.data.reports.map((i) => {
                    return (
                      <tr key={i._id} style={{ backgroundColor: "white" }}>
                        {this.state.role == 1 ? <td>{i.sms_sender}</td> : null}
                        <td>{i.phone}</td>
                        <td>{i.status}</td>
                        <td>{i.message}</td>
                        <td>{i.created}</td>
                      </tr>
                    );
                  })
                ) : (
                  <tr style={{ textAlign: "center", backgroundColor: "white" }}>
                    <td colSpan={4}>No data..</td>
                  </tr>
                )}
              </tbody>
            </Table>
            <ReactPaginate
              previousLabel={"< Prev"}
              nextLabel={"Next >"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={
                this.state.data && this.state.data.count
                  ? Math.ceil(this.state.data.count / 10)
                  : 1
              }
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={this.handlePageClick}
              containerClassName={"list-group-pagination pagination"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              disabledClassName={"page-item disabled"}
              activeClassName={"page-item active"}
            />
          </Col>
        </Row>
      </Container>
    );
  }
}
