import Cookies from "js-cookie";
const axios = require("axios");

class ExternalCallsApi {
    async getUrlClickStats(shortUrl) {
        try {
            const response = await axios.get('https://cors-anywhere.herokuapp.com/https://cutt.ly/api/api.php', {
                headers: { "Access-Control-Allow-Origin": '*' },
                params: {
                    key: '925c99bfdaa47c95ce680f7bc0d8230049c7d',
                    stats: shortUrl,
                },
            });
            console.log(response.data);
            return response.data;
        } catch (err) {
            console.log(err);
        }
    }
}

export default new ExternalCallsApi();