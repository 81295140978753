import React, { Component } from 'react'
import 'react-toastify/dist/ReactToastify.css'
import { Row, Col, Form, FormControl, Button } from 'react-bootstrap'
import bulkApi from '../../api/bulk.api'
import { toast } from 'react-toastify'
import uploadsApi from '../../api/uploads.api'

export default class SmsBulk extends Component {
  constructor(props) {
    super(props)
    this.state = {
      messageText: '',
      remainingCharacters: 160,
      remainingCharErr: null,
      tagLabel: '',
      tagLabelErr: null,
      selectedFile: null,
      numbersFileName: '',
      fileError: null,
      bulkOption: 0,
      inputFileKey: '',
      phoneNumber: '',
    }
    this.SendBulk = this.SendBulk.bind(this)
    this.SendSingleSms = this.SendSingleSms.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.onChangeHandler = this.onChangeHandler.bind(this)
  }
  async SendBulk() {
    try {
      if (this.validateForm()) {
        var response = await bulkApi.sendSmsBulk({
          text: this.state.messageText,
          campaignLabel: this.state.tagLabel,
          fileName: this.state.numbersFileName,
        })
        if (response.status === 200 && !response.data.status) {
          this.setState({
            messageText: '',
            remainingCharacters: 160,
            remainingCharErr: null,
            tagLabel: '',
            selectedFile: null,
            numbersFileName: '',
            fileError: null,
            tagLabelErr: null,
            inputFileKey: 'reset',
            phoneNumber: '',
          })
          toast.success('Success')
        }
        if (response.data.status == 400) {
          toast.warn(response.data.message)
        }
      }
    } catch (error) {
      toast.error('Messages are not sent')
    }
  }

  async SendSingleSms() {
    try {
      if (this.validateForm()) {
        var response = await bulkApi.sendSingleSmsBulk({
          text: this.state.messageText,
          campaignLabel: this.state.tagLabel,
          singlePhoneNumber: this.state.phoneNumber,
        })
        if (response.status === 200 && !response.data.status) {
          this.setState({
            messageText: '',
            remainingCharacters: 160,
            remainingCharErr: null,
            tagLabel: '',
            selectedFile: null,
            numbersFileName: '',
            fileError: null,
            tagLabelErr: null,
            inputFileKey: 'reset',
            phoneNumber: '',
          })
          toast.success('Success')
        }
        if (response.data.status == 400) {
          toast.warn(response.data.message)
        }
      }
    } catch (error) {
      toast.error('Message is not sent')
    }
  }

  handleChange(event) {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name

    if (target.name == 'messageText') {
      const remaining = 160 - (this.state.messageText.length + 1)
      this.setState({
        remainingCharacters: remaining > 0 ? remaining : 0,
      })
    }
    this.setState({
      [name]: value,
    })
  }
  validateForm() {
    let formValid = true

    if (this.state.messageText == '') {
      this.setState({ remainingCharErr: 'Please enter text' })
      formValid = false
    } else if (this.state.remainingCharacters < 0) {
      this.setState({
        remainingCharErr: 'The characters limit is 160',
      })
      formValid = false
    }
    if (this.state.numbersFileName == '' && this.state.phoneNumber == '') {
      this.setState({ fileError: 'Please choose file with numbers or enter single number' })
      formValid = false
    }
    if (this.state.tagLabel == '') {
      this.setState({ tagLabelErr: 'Please enter tag' })
      formValid = false
    }
    return formValid
  }

  async onChangeHandler(event) {
    try {
      const target = event.target.files[0].name.slice(
        ((event.target.files[0].name.lastIndexOf('.') - 1) >>> 0) + 2,
      )
      if (target.toLowerCase() == 'xlsx') {
        const data = new FormData()
        data.append('file', event.target.files[0])
        var response = await uploadsApi.uploadNumbers(data)
        this.setState({
          numbersFileName: response.data.filename,
          fileError: '',
        })
      } else {
        this.setState({
          fileError: 'File should have .xlsx extension',
        })
      }
    } catch (err) {
      console.log(err)
    }
  }

  render() {
    return (
      <Row className="table-section">
        <Col>
          <Form>
            <Form.Group as={Row}>
              <Col sm="10">
                <Col md={12}>
                  <Form.Label column sm="6" className="heading bold-text">
                    Enter text
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows="10"
                    name="messageText"
                    onChange={this.handleChange}
                    className="text-area"
                    value={this.state.messageText}
                  />
                  <Col md={12} className="mt-4">
                    <Row>
                      <Col md={6}>
                        <p className="error">{this.state.remainingCharErr}</p>
                      </Col>
                      <Col md={6}>
                        <label
                          className="col-md-12 mt-2 pull-right"
                          style={{ textAlign: 'right' }}
                        >
                          Characters left {this.state.remainingCharacters}
                        </label>
                      </Col>
                    </Row>
                  </Col>
                </Col>
                <Col xs={12} md={6} className="mt-4">
                  <label className="bold-text">Tag name</label>
                  <FormControl
                    type="text"
                    placeholder="Enter tag"
                    onChange={this.handleChange}
                    name="tagLabel"
                    value={this.state.tagLabel}
                  ></FormControl>
                  <p className="error">{this.state.tagLabelErr}</p>
                </Col>

                <Col xs={12} md={6} className="mt-4">
                  <label className="bold-text">Phone number</label>
                  <FormControl
                    type="text"
                    placeholder="Enter phone number"
                    onChange={this.handleChange}
                    name="phoneNumber"
                    value={this.state.phoneNumber}
                  ></FormControl>
                  {/* <p className="error">{this.state.fileError}</p> */}
                </Col>
                <Col md={6}>
                        <label
                          style={{ textAlign: 'left' }}
                        >
                          Phone number should be in format 3897XXXXXXX
                        </label>
                 </Col>

                <Col>
                  <label className="col-md-12 pl-0 bold-text">
                    Enter numbers
                  </label>
                  <input
                    type="file"
                    name="numbersFileName"
                    onChange={this.onChangeHandler}
                    key={this.state.inputFileKey || ''}
                    value={this.state.selectedFile}
                  />
                  <p className="error">{this.state.fileError}</p>
                </Col>
                <Col xs={12} md={6} className="mt-4">
                  <Button
                    variant="outline-warning mb-3 mt-3"
                    onClick={this.state.numbersFileName ? this.SendBulk : this.SendSingleSms}
                    sm="3"
                    size="sm"
                    className="margin-top-10px button-standard"
                  >
                    Send
                  </Button>
                </Col>
              </Col>
            </Form.Group>
            <Form.Group as={Row}></Form.Group>
          </Form>
        </Col>
      </Row>
    )
  }
}
