import React, { Component } from 'react'
import { Row, Col, Form, FormControl, Button, Table } from 'react-bootstrap'
import ReactPaginate from 'react-paginate'
import UserApi from '../../api/users.api'
import SendersApi from '../../api/senders.api'
import { Link } from 'react-router-dom'
import {getRoleName} from '../../common/utils'

export default class UsersList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      users: null,
      senders: null,
      page: 1,
      totalCount: 10,
      searchText: '',
      currentUserId: 0,
      currentUser: '',
      modalIsOpen: false,
    }
  }

  async getUsers(pageNumber) {
    try {
      const result = await UserApi.getAll(
        pageNumber ? pageNumber : this.state.page,
        this.state.totalCount,
        this.state.searchText,
      )
      this.setState({
        users: result.rows,
      })
    } catch (error) {
      console.log(error)
    }
  }

  async getSenders() {
    try {
      const result = await SendersApi.getAll()
      this.setState({
        senders: result,
      })
    } catch (error) {
      console.log(error)
    }
  }

  handleChange(event) {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name

    this.setState({
      [name]: value,
    })
  }

  async componentDidMount() {
    await this.getUsers()
  }

  async handlePageClick(e) {
    await this.getUsers(e.selected + 1)
  }

  async componentWillReceiveProps(props) {
    alert(props)
    console.log(props)
  }

  render() {
    return (
      <Row className="table-section">
        <Col lg={12}>
          <figure className="icon-group">
            <h2 className="heading ml-3">Users</h2>
          </figure>
          <Form inline onSubmit={this.handleSubmit}>
            <FormControl
              type="text"
              placeholder="Search client"
              className="mr-sm-2"
              onChange={this.handleChange}
              name="searchText"
            />
            <Button
              className="btn mr-2 button-standard"
              onClick={() => this.getUsers()}
            >
              <i className="material-icons">search</i>
              Search
            </Button>

            <Button component={Link} to="/new-user">
              <Link to="/new-user/" className="logged-user">
                {' '}
                + Add new{' '}
              </Link>
            </Button>
          </Form>
          <section className="list-box mt-3">
            <Table responsive="xl" className="mb-5">
              <thead className="table-head">
                <tr>
                  <th>Name</th>
                  <th>Viber Sender Name</th>
                  <th>SMS Sender Name</th>
                  <th>SMS Limit</th>
                  <th>Email</th>
                  <th>Role</th>
                </tr>
              </thead>
              <tbody>
                {this.state.users
                  ? this.state.users.map((i) => {
                      return (
                        <tr key={i._id}>
                          <td>{i.user_name}</td>
                          <td>{i.viber_sender}</td>
                          <td>{i.sms_sender}</td>
                          <td>{i.sms_limit}</td>
                          <td>{i.email}</td>
                          <td>{getRoleName(i.role_id)}</td>
                        </tr>
                      )
                    })
                  : null}
              </tbody>
            </Table>
            <ReactPaginate
              previousLabel={'< Prev'}
              nextLabel={'Next >'}
              breakLabel={'...'}
              breakClassName={'break-me'}
              pageCount={
                this.state.users && this.state.users.count
                  ? Math.ceil(this.state.users.count / 10)
                  : 1
              }
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={this.handlePageClick}
              containerClassName={'list-group-pagination pagination'}
              pageClassName={'page-item'}
              pageLinkClassName={'page-link'}
              previousClassName={'page-item'}
              previousLinkClassName={'page-link'}
              nextClassName={'page-item'}
              nextLinkClassName={'page-link'}
              disabledClassName={'page-item disabled'}
              activeClassName={'page-item active'}
            />
          </section>
        </Col>
      </Row>
    )
  }
}
