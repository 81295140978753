import React, { Component } from 'react'
import 'react-toastify/dist/ReactToastify.css'
import vscimage from '../images/vsc.png'

export default class HomePage extends Component {
  render() {
    return (
      <div style={{ textAlign: 'center' }}>
        <img src={vscimage} alt="logo" />
      </div>
    )
  }
}
