import React, { Component } from 'react'
import 'react-toastify/dist/ReactToastify.css'
import { Row, Col, Form, FormControl, Button, Table } from 'react-bootstrap'
import { toast } from 'react-toastify'
import uploadsApi from '../../api/uploads.api'
import { MessageTypes } from '../../common/enums'
import UploadsAPI from '../../api/uploads.api'
import bulkApi from '../../api/bulk.api'

export default class AllCombinations extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedFile: null,
      numbersFileName: '',
      fileError: null,
      bulkOption: 0,
      selectedimage: null,
      imageFileErr: null,
      imageFileName: '',
    }
    this.SendBulk = this.SendBulk.bind(this)
    this.onChangeimageHandler = this.onChangeimageHandler.bind(this)
    this.onChangeHandler = this.onChangeHandler.bind(this)
  }

  validateForm() {
    let formValid = true

    if (this.props.messageText == '') {
      this.setState({ remainingCharErr: 'Enter text' })
      formValid = false
    } else if (this.props.remainingCharacters < 0) {
      this.setState({
        remainingCharErr: 'Text limit is 1000 characters',
      })
      formValid = false
    }
    if (this.state.numbersFileName == '') {
      this.setState({ fileError: 'Please upload numbers file' })
      formValid = false
    }
    if (this.props.imageUrl == '') {
      if (this.state.imageFileName == '') {
        this.setState({
          imageUrlErr: 'Please upload the numbers',
        })
        formValid = false
      }
    }
    if (this.props.buttonText == '') {
      this.setState({ buttonTextErr: 'Please enter button text' })
      formValid = false
    }
    if (this.props.buttonUrl == '') {
      this.setState({ buttonUrlErr: 'Please enter button url' })
      formValid = false
    }
    if (this.props.tagLabel == '') {
      this.setState({ tagLabelErr: 'Please enter tag' })
      formValid = false
    }
    return formValid
  }
  messageLabelChange(event) {
    this.setState({ messageLabelType: event.value })
  }

  async onChangeimageHandler(event) {
    try {
      const target = event.target.files[0].name.slice(
        ((event.target.files[0].name.lastIndexOf('.') - 1) >>> 0) + 2,
      )
      if (target.toLowerCase() == 'png' || target.toLowerCase() == 'jpg') {
        const data = new FormData()
        data.append('file', event.target.files[0])
        var response = await UploadsAPI.uploadImage(data)
        this.setState({ imageFileName: response.data.filename, fileError: '' })
      } else {
        this.setState({
          imageFileErr:
            'Incorrect file extension. Only jpg or png are accepted',
        })
      }
    } catch (err) {
      console.log(err)
    }
  }
  async onChangeHandler(event) {
    try {
      const target = event.target.files[0].name.slice(
        ((event.target.files[0].name.lastIndexOf('.') - 1) >>> 0) + 2,
      )
      if (target.toLowerCase() == 'xlsx') {
        const data = new FormData()
        data.append('file', event.target.files[0])
        var response = await uploadsApi.uploadNumbers(data)
        this.setState({
          numbersFileName: response.data.filename,
          fileError: '',
        })
      } else {
        this.setState({
          fileError: 'File should have .xlsx extension',
        })
      }
    } catch (err) {
      console.log(err)
    }
  }

  async SendBulk() {
    this.setState({ messageText: '' })
    if (this.validateForm()) {
      await bulkApi.sendBulk({
        text: this.props.messageText,
        imageUrl: `${process.env.REACT_APP_IMAGES_URL}/${this.state.imageFileName}`,
        fileName: this.state.numbersFileName,
        btnText: this.props.buttonText,
        btnUrl: this.props.buttonUrl,
        tagLbl: this.props.tagLabel,
        messageType: MessageTypes.TextImageButton,
      })
      this.props.resetState()
      this.setState({
        imageUrl: '',
        imageUrlErr: null,
        selectedFile: null,
        numbersFileName: '',
        fileError: null,
        selectedimage: null,
        imageFileErr: null,
        imageFileName: '',
      })
      toast.success('Message has been sent')
    }
  }

  render() {
    return (
      <Col>
        <Form>
          <Form.Group as={Row}>
            <Col sm="10">
              <Col md={12}>
                <Form.Label column sm="6" className="heading bold-text">
                  Enter text
                </Form.Label>
                <Form.Control
                  as="textarea"
                  rows="10"
                  name="messageText"
                  onChange={this.props.handleChange}
                  className="text-area"
                  value={this.props.messageText}
                />
                <Col md={12} className="mt-4">
                  <Row>
                    <Col md={6}>
                      <p className="error">{this.state.remainingCharErr}</p>
                    </Col>
                    <Col md={6}>
                      <label
                        className="col-md-12 mt-2 pull-right"
                        style={{ textAlign: 'right' }}
                      >
                        Remaining Characters {this.props.remainingCharacters}
                      </label>
                    </Col>
                  </Row>
                </Col>
              </Col>             
              <Col>
                <label className="col-md-12 pl-0 bold-text">
                  Browse image
                </label>
                <input
                  type="file"
                  name="imageFileName"
                  onChange={this.onChangeimageHandler}
                  value={this.state.selectedimage}
                />
                <p className="error">{this.state.imageUrlErr}</p>
              </Col>
              <Col xs={12} md={6} className="mt-4">
                <label className="bold-text">Button url</label>
                <FormControl
                  type="text"
                  placeholder="Enter button url"
                  onChange={this.props.handleChange}
                  name="buttonUrl"
                  value={this.props.buttonUrl}
                ></FormControl>
                <p className="error">{this.state.buttonUrlErr}</p>
              </Col>
              <Col xs={12} md={6} className="mt-4">
                <label className="bold-text">Button text</label>
                <FormControl
                  type="text"
                  placeholder="Enter button text"
                  onChange={this.props.handleChange}
                  name="buttonText"
                  value={this.props.buttonText}
                ></FormControl>
                <p className="error">{this.state.buttonTextErr}</p>
              </Col>
              <Col xs={12} md={6} className="mt-4">
                <label className="bold-text">Tag</label>
                <FormControl
                  type="text"
                  placeholder="Enter tag"
                  onChange={this.props.handleChange}
                  name="tagLabel"
                  value={this.props.tagLabel}
                ></FormControl>
                <p className="error">{this.state.tagLabelErr}</p>
              </Col>
              <Col>
                <label className="col-md-12 pl-0 bold-text">
                  Upload numbers
                </label>
                <input
                  type="file"
                  name="numbersFileName"
                  onChange={this.onChangeHandler}
                  value={this.state.selectedFile}
                />
                <p className="error">{this.state.fileError}</p>
              </Col>
              <Col xs={12} md={6} className="mt-4">
                <Button
                  variant="outline-warning mb-3 mt-3"
                  onClick={this.SendBulk}
                  sm="3"
                  size="sm"
                  className="margin-top-10px button-standard"
                >
                  Send
                </Button>
              </Col>
            </Col>
          </Form.Group>
          <Form.Group as={Row}></Form.Group>
        </Form>
      </Col>
    )
  }
}
